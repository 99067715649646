// Libs
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import flatten from 'lodash.flatten';
import { graphql, StaticQuery } from 'gatsby';

// Components
import Layout from 'components/layout';
import SEO from 'components/seo';
import { device } from 'components/device';
import MedicalSpecialties from 'components/medicalSpecialties';
import FeaturedMarketing from 'components/featuredMarketing';

// Methods
import { removeItemFromSpecialtyList } from 'utils/removeItemFromSpecialtyList';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props =>
    !props.isHome && !props.isDesktop && '0 0 1.25rem 1.25rem'};
  @media ${device.mobile} {
    padding-top: ${props => props.isHome && '1rem'};
  }
`;
const Part = styled.div`
  position: relative;
  overflow: ${props => props.overflow};
  grid-column: ${props => props.gridColumn};
  @media ${device.laptop} {
    margin-bottom: 0;
  }
  @media ${device.tablet} {
    margin: ${props => props.teaching && '0 -20px'};
  }
`;
const getMarkdown = (
  page,
  fragmentId,
  isMarkdown = false,
  assets = false,
  assetsMultiple = false
) => {
  const fragments = page[0].fragments;
  const findFragment = fragments.find(item => item.id === fragmentId);
  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else if (assetsMultiple) {
    return findFragment.assetpickerMultiple;
  } else {
    return flatten(findFragment.localizations);
  }
};

const handleStateSelectDefault = () => {
  switch (window.location.hash) {
    case '#brasilia':
      localStorage.setItem('unit', 'Brasília, DF');
      break;
    case '#sao-paulo':
      localStorage.setItem('unit', 'São Paulo, SP');
      break;
    default:
      return;
  }
};

const RenderPage = (page, isDesktop, specialties, allPages) => {
  const filteredSpecialties = removeItemFromSpecialtyList(specialties);

  return (
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / -2'}>
        <div style={{ overflow: 'hidden' }}>
          <FeaturedMarketing
            markdown={getMarkdown(page, 'ckp2yv34gvz2i0b75ckqfleti', true)}
            fragments={getMarkdown(page, 'ckp2yv34gvz2i0b75ckqfleti')}
            asset={getMarkdown(page, 'ckp2yv34gvz2i0b75ckqfleti', false, true)}
            breadCrumb={getMarkdown(page, 'ckp2z5ws8w0m40a22xjk01yqa', true)}
            boxInfoTextWidthDesk="95%"
            boxContentPaddingBottomDesk="0"
            boxContentHeightDesk="24rem"
            positionImageMarketingDesk="6rem"
            positionImageMarketingLap="6rem"
            boxContentNormalDirectionLap
            titleWidthLap="15rem"
            titleWidthTab="15rem"
            boxInfoTextWidthMobile="90%"
            boxInfoTextWidthMobile425="95%"
            boxInfoTextWidthMobile320="77%"
            boxInfoTextHeightMobile320
            titleMarginLap="0 !important"
            widthImgMarketingLap="15rem"
            positionTopImageMarketingMobile="1.5rem"
            boxContentHeightLap="10.5rem"
            boxContentHeightMobile="15rem"
            paddingTopBoxInfoMobile="0"
            justifyContentBoxInfoMobile="flex-start"
            titleLineHeightTab="1.875rem"
            paddingTopTextBoxInfo="0.625rem"
            titleWidthMob="15rem"
            widthImgMarketingMobile="15.625rem"
            overflowContainerMobile="hidden"
            positionLeftImageMarketingMobile="-3.125rem"
            positionLeftImageMarketingMobile375="-2.5rem"
            positionLeftImageMarketingMobile320="-5.5rem"
            marginBreadCrumb="0"
            containerMarginBottomLap="0"
            marginBottomBoxInfoTab="0"
            boxInfoHasNoPaddingMob="0"
            marginBottomTitleDesk="1.875rem"
            boxContentMarginTopLap="-1rem"
            marginTopBoxInfoLap="5rem !important"
            containerNoBorderBottomLap
          />
        </div>

        <MedicalSpecialties
          specialties={filteredSpecialties}
          allPages={allPages.filter(item =>
            item.node.path.includes('especialidades-medicas/sao-paulo/')
          )}
        />
      </Part>
    </GridContainer>
  );
};
const IndexPage = ({ page, specialties, allPages }) => {
  const [isDesktop, setDesktop] = useState(true);
  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };
  useEffect(() => {
    handleStateSelectDefault();
  }, []);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });
  return (
    <Layout overflow="initial">
      <SEO dataSeo={page} />
      {RenderPage(page, isDesktop, specialties, allPages)}
    </Layout>
  );
};
export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allSitePage {
            edges {
              node {
                id
                path
              }
            }
          }
          gcms {
            specialties {
              name
              unity
              slug
              customUrl
            }
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckp2ynox4vvi30d73hprzq0bm" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                  assetpickerMultiple {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
            pages(where: { id: "ckp2ynox4vvi30d73hprzq0bm" }) {
              fragments {
                id
                name
                markdown
                localizations {
                  markdown
                  singletexts
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <IndexPage
            specialties={response.gcms.specialties}
            page={response.gcms.site.pages}
            allPages={response.allSitePage.edges}
          />
        );
      }}
    />
  );
};
