// Libs
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import OurUnits from 'components/OurUnits';
import flatten from 'lodash.flatten';
import { Link } from 'gatsby';

// Components
import { device } from 'components/device';
import { fixItem } from 'utils/fixItem';
import { HeaderContext } from '../context/header';

// Styled
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 4.3125rem;
  position: relative;

  @media ${device.tablet} {
    padding-right: 1.25rem;
    margin-top: 1.375rem;
  }
`;

const BoxUnits = styled.div`
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  top: 9.125rem;
  background-color: #fff;
  padding: 2.3rem 0px 1.25rem 0;

  @media ${device.laptop} {
    position: ${props => props.scroll && 'fixed'};
    top: 8rem;
  }
`;

const ContentSpecialties = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 6.25rem;
  border-bottom: ${props => (props.lastOne ? 'none' : '1px solid #C6C8CC')};
  padding-bottom: 4.375rem;

  h2 {
    font-weight: 400;
    font-size: 3.375rem;
    text-transform: uppercase;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 1.875rem;

    h2 {
      margin-bottom: 1.875rem;
    }
  }

  @media ${device.mobile} {
    padding-bottom: 1.875rem;
  }
`;

const ContainerSpecialties = styled.div`
  display: flex;
  flex-direction: column;
  width: 35.625rem;

  @media ${device.mobile425} {
    width: 23.625rem;
  }

  @media ${device.mobile395} {
    width: 21.625rem;
  }

  @media ${device.mobile375} {
    width: 35.625rem;
  }
`;

const Specialty = styled(Link)`
  width: 100%;
  color: #45a7df;
  line-height: 1.875rem;
  cursor: pointer;
  margin-bottom: 3.125rem;
  font-weight: 400;
  font-size: 1.5rem;

  @media ${device.mobile} {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  @media ${device.mobile375} {
    width: 49%;
  }

  :last-child {
    margin: 0;
  }

  :hover {
    text-decoration: underline;
  }
`;

const MedicalSpecialties = props => {
  const [spSpecialties, setSpSpecialties] = useState(undefined);
  const [locationsUnits, setLocationsUnits] = useState('São Paulo, SP');
  const [bsbSpecialties, setBsbSpecialties] = useState(undefined);
  const [itaimSpecialties, setItaimSpecialties] = useState(undefined);

  const { fixedSelect } = useContext(HeaderContext);

  let letterList = [];
  let newList = [];

  const searchUnity = unity =>
    props.specialties.filter(i => i.unity.includes(unity));

  const storageData =
    typeof window !== 'undefined' && localStorage.getItem('unit');

  useEffect(() => {
    setSpSpecialties(searchUnity('sp'));
    setBsbSpecialties(searchUnity('bsb'));
    setItaimSpecialties(searchUnity('itaim'));

    if (storageData !== null) {
      setLocationsUnits(localStorage.getItem('unit'));
    }
  }, [locationsUnits]);

  const renderSpecialties = (list, unity) => {
    const firstLetter = list && list.map(item => item.name?.charAt(0));
    firstLetter &&
      firstLetter.forEach(item => {
        const fixingLetter = fixItem(item);

        const includesItem = letterList.includes(fixingLetter);

        if (!includesItem) {
          letterList.push(fixingLetter);
        }
      });

    newList = letterList.map(el =>
      list.filter(item => {
        const fixEl = fixItem(el);

        const letters = flatten(fixItem(item.name?.charAt(0)));

        return letters.includes(fixEl);
      })
    );

    const gettingLetters = list =>
      list.map(item => fixItem(item.name?.charAt(0)));

    const fixSpecialty = (slug, customUrl) => {
      if (customUrl) {
        return customUrl;
      } else {
        for (const item of props.allPages) {
          if (
            item.node.path.includes(`${unity}/${slug !== undefined && slug}`)
          ) {
            return `/especialidades-medicas/${unity}/${
              slug !== undefined && slug
            }`;
          }
        }

        return `/especialidades-medicas/${unity}/${slug !== undefined && slug}`;
      }
    };

    return newList
      .sort((a, b) => (gettingLetters(a) > gettingLetters(b) ? 1 : -1))
      .map((element, index) => (
        <ContentSpecialties
          key={element[0]?.name}
          lastOne={newList.length - 1 === index}
        >
          <h2>{fixItem(element[0]?.name?.charAt(0))}</h2>
          <ContainerSpecialties>
            {element
              .sort((a, b) =>
                a.name.toUpperCase() < b.name.toUpperCase()
                  ? -1
                  : a.name.toUpperCase() > b.name.toUpperCase()
                  ? 1
                  : 0
              )
              .map(el => (
                <Specialty
                  key={el.slug}
                  to={fixSpecialty(el.slug, el.customUrl)}
                  target="_blank"
                >
                  {el.name}
                </Specialty>
              ))}
          </ContainerSpecialties>
        </ContentSpecialties>
      ));
  };

  const renderSpecialtiesList = () => {
    switch (locationsUnits) {
      case 'São Paulo, SP':
        return renderSpecialties(spSpecialties, 'sao-paulo');
      case 'Brasília, DF':
        return renderSpecialties(bsbSpecialties, 'brasilia');
      case 'Itaim':
        return renderSpecialties(itaimSpecialties, 'itaim');
      default:
        return;
    }
  };

  return (
    <Container>
      <BoxUnits scroll={fixedSelect}>
        <OurUnits
          specialties
          locationsUnits={locationsUnits}
          setLocationsUnits={setLocationsUnits}
        />
      </BoxUnits>
      {renderSpecialtiesList()}
    </Container>
  );
};

export default MedicalSpecialties;
