const removeFromList = ['OutubroRosa2022', 'DezembroLaranja2022'];

export const removeItemFromSpecialtyList = array => {
  const newArray = [...array];
  if (newArray.length > 0) {
    removeFromList.forEach(name => {
      const removeItemIndex = newArray.findIndex(item => item.name === name);
      if (removeItemIndex > -1) {
        newArray.splice(removeItemIndex, 1);
      }
    });
  }
  return newArray;
};
